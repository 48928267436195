import React, { useState, useEffect } from "react";
import './XBO.css';
import '../App.css';
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import { ethers } from "ethers";
import pop_abi from "../abi/pop_abi.json";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuTools from "./menuTools";
import { connect } from "../redux/blockchain/blockchainActions";



function XBO() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [signer, setSigner] = useState(null);
  const [senderLogs, setSenderLogs] = useState(null);
  const [loading, setLoading] = useState(false);  
  const [defenderId, setDefenderId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(true);
  const [showForm, setShowForm] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [showButton, setShowButton] = useState(true);
  const contractAddress = "0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e";
  const xenboxAddress = "0xF9656b209BAF25b6c244E11736a675d30EB45DFF";
  const popAddress = "0x1398f24881056318097D6F00652697dde50aaa93";

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setShowForm(false);
  };

  const handleButtonClick = () => {
    setShowButton(false);
  };

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);
      setAccount(accounts[0]);
    } else {
      console.log("Please install Metamask.");
    }
  };
  
  const claim = async () => {
    try {
      const signer = provider.getSigner();
      const contract = new ethers.Contract(
        popAddress, 
        pop_abi, 
        signer);      
      const tx = await contract.mineXenbox(inputValue);
      await tx.wait();
      console.log("Xenbox Mined");
    } catch (err) {
      console.error(err);
    }
  };

  const multiclaim = async () => {
    try {
      const signer = provider.getSigner();
      const contract = new ethers.Contract(popAddress, pop_abi, signer);
  
      for (const player of data.allOwnerOfPlayers) {
        const tx = await contract.mineXenbox(player.id);
        await tx.wait();
        console.log(`Xenbox Mined for player ${player.id}`);
      }
    } catch (err) {
      console.error(err);
    }
  };  

  const XboColumn = ({ className, children }) => {
    const [active, setActive] = useState(false);
  
    const handleColumnClick = (e) => {
      if (e.target === e.currentTarget) {
        setActive(!active);
      }
    };
  
    return (
      <div className={`xbo-column ${className} ${active ? 'active' : ''}`} onClick={handleColumnClick}>
        {active && children}
      </div>
    );
  };

  const handleFunction1 = (value) => {
    setInputValue(value);
    claim();
  };

  const handleFunction2 = () => {
    multiclaim();
  };

  const handleSubmit = (e, func) => {
    e.preventDefault();
    const value = e.target.elements.input1.value;
    func(value);
  };

  useEffect (() => {
    initConnection();  
  }, []);

  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (!provider || !signer) return;
  
    const contracto = new ethers.Contract(popAddress, pop_abi, signer);
    contracto.on("RewardClaimedByMiner", (sender, rewards, event) => {
      let data = {
        sender: sender.toString(), rewards: rewards.toString(), event
      };
      setSenderLogs((data))
    });
  }, [provider, signer]);

  return (
    <s.Screen image={cerberus}>
      <Layout />

      <div className="xbo-container">
            <XboColumn className="col-1">
              <form onSubmit={(e) => handleSubmit(e, handleFunction1)} className="xbo-form">
                <input name="input1" type="text" placeholder="Xenomorph ID" />
                <button type="submit">Claim One</button>
              </form>
            </XboColumn>
            <XboColumn className="col-2">
              <button onClick={handleFunction2} className="xbo-form xbo-button">
                Claim All ({data.allOwnerOfPlayers.length})
              </button>
            </XboColumn>
      </div>
        
      <div className='notificationsbar'>
            {senderLogs && (
                <div className="stakenotify texttheme tgoldenrod">
                  Reward claim successful! 
                  <span style={{color: "white"}}>Receipent:</span> 
                  ...{senderLogs.sender.substring(senderLogs.sender.length - 12)} 
                  <span style={{color: "white"}}>Rewards:</span> {senderLogs.rewards/(10**18).toFixed()} $XBO
                </div>
              )}
      </div>
      <MenuTools />
      </s.Screen>
  );
}

export default XBO;