import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import abi from "../abi/abi.json";
import xenbox_abi from "../abi/xenbox_abi.json";
import pop_abi from "../abi/pop_abi.json";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import { GiWingedEmblem } from "react-icons/gi";
import { BsShieldLock } from "react-icons/bs";

function Layout() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [balancer, setBalancer] = useState("");
  const [xbalancer, setXbalancer] = useState("");  
  const [claim, setClaim] = useState("");
  const [remainingTime, setRemainingTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const contractAddress = "0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e";
  const xenboxAddress = "0xF9656b209BAF25b6c244E11736a675d30EB45DFF";
  const popAddress = "0x1398f24881056318097D6F00652697dde50aaa93";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);
      setAccount(accounts[0]);
    } else {
      console.log("Please install Metamask.");
    }
  };

  const bal = async () => {    
    setLoading(true);
    const contractERC = new ethers.Contract(
      contractAddress,
      abi,
      provider
    );
    const tempBal = await contractERC.balanceOf(account);
    setBalancer(tempBal.toString());
  };

  const xbal = async () => {
    const contracto = new ethers.Contract(
      xenboxAddress,
      xenbox_abi,
      provider
    );
    const tempxBal = await contracto.balanceOf(account);
    setXbalancer(tempxBal.toString());
  };

  const unclaimed = async () => {
    try {
      const signer = provider.getSigner();
      const contract = new ethers.Contract(popAddress, pop_abi, signer);
      
      // Step 1: Get the timelock variable value
      let tl = await contract.timeLock();
      const timelock = tl.toString();
      
      let multi = await contract.multiplier();
      const multiplier = multi.toString();
      let hab = await contract.hatchbonus();
      const hatchbonus = hab.toString();
      let lb = await contract.levelbonus();
      const levelbonus = lb.toString();
      let wb = await contract.winsbonus();
      const winsbonus = wb.toString(); 
      let fb = await contract.fightsbonus();
      const fightsbonus = fb.toString();
      let hib = await contract.historybonus();
      const historybonus = hib.toString();
      
      // Step 2: Loop through each ID and calculate the unclaimed amount
      const idArray = data.allOwnerOfPlayers.map(player => Number(player.id));
      let unclaimedAmount = 0;
      for (const playerId of idArray) {
        const player = data.allOwnerOfPlayers.find(player => player.id === playerId.toString());
        const hatch = player.hatch;
        const level = player.level;
        const wins = player.wins;
        const fights = player.fights;
        const history = player.history;

        console.log(data);
        console.log (player.hatch);
        console.log(player.level);
        console.log(player.wins);
        console.log(player.fights);
        console.log(player.history);
  
        const playerData = await contract.Collectors(playerId);
        const minerClaims = await contract.MinerClaims(playerId);
        const claimDate = minerClaims.toString() !== "0" ? new Date(Number(minerClaims) * 1000) : null;
        const currentTime = new Date();
        console.log(currentTime);
        console.log(claimDate);
                  
        const hatchfactor = hatchbonus * player.hatch;

        if (!claimDate) {
          // If the user has never claimed before
          const claimable = (player.hatch * multiplier) + (((level - playerData.level) * levelbonus) + hatchfactor) + 
            (((wins - playerData.wins) * winsbonus) + hatchfactor) + 
            (((fights - playerData.fights) * fightsbonus) + hatchfactor) + 
            (((history - playerData.history) * historybonus) + hatchfactor);
          unclaimedAmount += claimable;
        } else if (new Date(claimDate.getTime() + (timelock * 1000)) <= currentTime) {
          // If the user has claimed before and the timelock has expired
          const claimable = (player.hatch * multiplier) + (((level - playerData.level) * levelbonus) + hatchfactor) + 
          (((wins - playerData.wins) * winsbonus) + hatchfactor) + 
          (((fights - playerData.fights) * fightsbonus) + hatchfactor) + 
          (((history - playerData.history) * historybonus) + hatchfactor);
          unclaimedAmount += claimable;
        } else {
          // If the user has claimed before but the timelock has not expired
          const timeRemaining = new Date(claimDate.getTime() + (timelock * 1000)) - currentTime;
          setRemainingTime(timeRemaining);
          const claimable = (((level - playerData.level) * levelbonus) + hatchfactor) + 
          (((wins - playerData.wins) * winsbonus) + hatchfactor) + 
          (((fights - playerData.fights) * fightsbonus) + hatchfactor) + 
          (((history - playerData.history) * historybonus) + hatchfactor);
          unclaimedAmount += claimable;
        }
      }
  
      // Step 3: Set the state of unclaimedEntry to the total unclaimed amount
      setClaim(unclaimedAmount.toString());
      console.log(unclaimedAmount);
    } catch (err) {
      console.error(err);
    }
  };
  

  const allbalances = () => {
    unclaimed();
      bal();
      xbal();
  };

  useEffect (() => {
  initConnection();  
  }, []);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  return ( 
      <div className="header">
          <Link to="/" className={"textstyle"}><img src={require(`./xenbox_w_txt.png`)} style={{height: "20px"}}/></Link>              
          {balancer == "" ?        
            <button className='button0' onClick={allbalances}>Load Balances</button>
               : (
                <div style={{color: "white"}}>
                <span style={{color: "gold"}}>{(Number(balancer)/10**18).toFixed()}</span> $XEN <span style={{color: "goldenrod"}}> + </span>
                <span style={{color: "gold"}}>{(Number(xbalancer)/10**18).toFixed()}</span> $XBO <span style={{color: "goldenrod"}}>Available</span>
                {claim == "" ? null 
                : ( <span style={{color: "gold"}}> + <span style={{color: "violet"}}>{claim}</span><span style={{color: "goldenrod"}}> Unclaimed </span> </span>
                )}
                {remainingTime > 0 && (
                <span title={`Locked Claim: Claimable only after ${new Date(Date.now() + remainingTime).toLocaleString()}`}><BsShieldLock /></span>
                )}
                <Link to="/XBO" className='linkbutton textstyle'><span style={{color: "violet"}}><GiWingedEmblem/></span><span> CLAIM XBO </span><span style={{color: "violet"}}><GiWingedEmblem/></span></Link>
                </div>
                )} 
          {account == "" ? 
            <button  onClick={initConnection} className={"button1"}>
              Connect Wallet
            </button>
            : (
              <p>...{account.substring(account.length - 5)} </p> )}
        </div> 
  );
}

export default Layout;