import React, { useEffect, useState } from "react";
import "../App.css";
import { Link } from "react-router-dom";
import MenuLock from "./menuLock";

function MenuBegin() {
  
  return (
    <div>
     <MenuLock />
     
      <div className="menu2">
      <div className="spinner" title="Build Your Army"><img src={require("../assets/images/icons/start.png")} className="icons" /></div>
      <Link to="/arsenal" ><div title="Go to Arsenal"><img src={require("../assets/images/icons/arsenal1.png")} className="icons" /></div></Link>
      <Link to="/mint" ><div title="Mint a Xenomorph"><img src={require("../assets/images/icons/mint1.png")} className="icons" /></div></Link>
      <Link to="/hatch-xenomorph" ><div title="Hatch a Xenomorph"><img src={require("../assets/images/icons/hatch1.png")} className="icons" /></div> </Link>
      <div className="spinner" title="Read Guidebook"><a href="https://xenomorphguide.xenbox.xyz" target="_blank"> <img src={require("../assets/images/icons/guidebook.png")} className="icons" /></a> </div>

      </div>

      
      
    </div>
  );
}

export default MenuBegin;
