import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import MenuLock from "./menuLock";

function MenuTools() {
  
  return (
    <div>
      <MenuLock />

      <div className="menu2">
      <Link to="/arsenal" ><div className="spinner" title="Leave Toolspace"><img src={require("../assets/images/icons/tools.png")} className="icons" /></div></Link> 
      <Link to="/update-name" ><div title="Assign another nickname"><img src={require("../assets/images/icons/rename1.png")} className="icons" /></div></Link>
      <Link to="/harvester" ><div title="Harvest Xen with Xenbox"><img src={require("../assets/images/icons/miner1.png")} className="icons" /></div></Link>      
      <Link to="/reset-timer" ><div title="Reset Your Call Limit"><img src={require("../assets/images/icons/resetTimer1.png")} className="icons" /></div></Link>
      <div className="spinner" title="Read Guidebook"><a href="https://xenomorphguide.xenbox.xyz" target="_blank"> <img src={require("../assets/images/icons/guidebook.png")} className="icons" /></a> </div>

      </div>

    </div>
  );
}

export default MenuTools;
