import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import MenuLock from "./menuLock";

function MenuManage() {

  return (
    <div>
      <MenuLock />

      <div className="menu2" style={{minHeight: "73%"}}>
      <Link to="/arsenal" ><div className="spinner" title="Leave Winnings"><img src={require("../assets/images/icons/manage.png")} className="icons" /></div></Link> 
      <Link to="/claim-assault" ><div title="Claim Assaults for a win"><img src={require("../assets/images/icons/claimAssault1.png")} className="icons" /></div></Link>
      <Link to="/claim-debilitate" ><div title="Claim Debilitations for a win"><img src={require("../assets/images/icons/claimDebilitate1.png")} className="icons" /></div></Link>
      <Link to="/level-up" ><div title="Level up a Xenomorph"><img src={require("../assets/images/icons/levelup1.png")} className="icons" /></div></Link>
      <Link to="/payouts" ><div title="Withdraw Your Xen Winnings"><img src={require("../assets/images/icons/payout1.png")} className="icons" /></div></Link>
      <div className="spinner" title="Read Guidebook"><a href="https://xenomorphguide.xenbox.xyz" target="_blank"> <img src={require("../assets/images/icons/guidebook.png")} className="icons" /></a> </div>

      </div>
    </div>
  );
}

export default MenuManage;
