import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuTools from "./menuTools";
import { connect } from "../redux/blockchain/blockchainActions";

function ResetTimer() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);  
  const [attackerId, setAttackerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  console.log(data);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const resetTimer = (_account, _playerId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .resetFunctionCalls(_playerId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);        
        setErrorMessage("Failed? Contract paused or can't reset timer twice in 24hrs!");       
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});       
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    const totalSupply = data.allPlayers.length - 1;

     // Check ID is not an empty string
     if (attackerId === "") {
      setErrorMessage("IDs cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is a valid integer
    if (!integerRegex.test(attackerId)) {
      setErrorMessage("IDs must be positive integers.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is greater than 0 and less than or equal to totalSupply
    if (attackerId <= 0  || attackerId > totalSupply) {
      setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the player owns the Xenomorph
    const ownedXenomorphs = data.allOwnerOfPlayers.filter(player => player.id === attackerId);
    if (ownedXenomorphs.length === 0) {
      setErrorMessage("You do not own this Xenomorph.");
      setErrorMessageVisible(true);
      return;
    }

    // Everything is valid, assault the Xenomorph
    resetTimer(blockchain.account, attackerId);
  }; 
  
  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);


  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transreset"}/>

      <div className={"translation"} 
                 style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <img src={require("../assets/images/timer.gif")} className="portraitRST"/>              
              <div>
              <input className={"inputform"} style={{color: "white", border: "3px solid rgb(113, 50, 2)"}}
                type="text"
                placeholder="Your Xenomorph ID"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />
          </div>
            <button className={"button1 textstyle"}
            disabled={loading ? 1 : 0}
            onClick={(e) => {
              e.preventDefault();
              resetTimer(blockchain.account, attackerId);
            }}
          >
            Reset
          </button> 
          </div>
        <div className={"division3 transoreset"} />
            
      <MenuTools />
      <Notifications />
    </s.Screen>
  );
}

export default ResetTimer;