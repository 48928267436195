import React from "react";
import "../App.css";
import { Link } from "react-router-dom";
import MenuLock from "./menuLock";

function MenuGameplay() {
  

  return (
    <div>
      <MenuLock />

      <div className="menu2" style={{minHeight: "72%"}}>
      <Link to="/arsenal" ><div className="spinner" title="Leave Arena"><img src={require("../assets/images/icons/gameplay.png")} className="icons" /></div></Link> 
      <Link to="/assault" ><div title="Assault a Xenomorph"><img src={require("../assets/images/icons/assault1.png")} className="icons" /></div></Link>
      <Link to="/debilitate" ><div title="Debilitate an Enemy"><img src={require("../assets/images/icons/debilitate1.png")} className="icons" /></div></Link>
      <Link to="/weaponize" ><div title="Weaponize Your Xenomorphs"><img src={require("../assets/images/icons/weaponize1.png")} className="icons" /></div></Link>    
      <Link to="/regenerate" ><div title="Regenerate a Xenomorph"><img src={require("../assets/images/icons/regenerate1.png")} className="icons" /></div></Link>
      <div className="spinner" title="Read Guidebook"><a href="https://xenomorphguide.xenbox.xyz" target="_blank"> <img src={require("../assets/images/icons/guidebook.png")} className="icons" /></a> </div>

      </div>
      
      
      
    </div>
  );
}

export default MenuGameplay;
