import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuManage from "./menuManage";
import { connect } from "../redux/blockchain/blockchainActions";

function LevelUp() {

  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);    
  const [attackerId, setAttackerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  console.log(data);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const levelup = (_account, _playerId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .levelUp(_playerId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    const totalSupply = data.allPlayers.length - 1;

     // Check ID is not an empty string
     if (attackerId === "") {
      setErrorMessage("ID cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is a valid integer
    if (!integerRegex.test(attackerId)) {
      setErrorMessage("ID must be positive integers.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is greater than 0 and less than or equal to totalSupply
    if (attackerId <= 0  || attackerId > totalSupply) {
      setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the player owns the Xenomorph
    const ownedXenomorphs = data.allOwnerOfPlayers.filter(player => player.id === attackerId);
    if (ownedXenomorphs.length === 0) {
      setErrorMessage("You do not own this Xenomorph.");
      setErrorMessageVisible(true);
      return;
    }

    // Check if Xenomorph has unclaimed wins 
    const dXenomorph = data.allOwnerOfPlayers.find(x => x.id === attackerId);
    if (dXenomorph.wins < 5) {
      setLoading(false);
      setErrorMessage("Xenomorph has insufficient wins!");
      setErrorMessageVisible(true);
      return;
    }

    // Everything is valid, assault the Xenomorph
    levelup(blockchain.account, attackerId);
  };  

  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 translevel"} />
      <div className={"translation"} 
                 style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              <img src={require("../assets/images/leveler.gif")} className="portraitLVL"/>              
              <div style={{padding: "2px"}}>
              <input className={"inputform"} style={{color: "white", border: "3px solid rgb(170, 110, 19)"}}
                type="text"
                placeholder="Your Xenomorph ID"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />

              {/* Show error message if input is invalid */}
              {errorMessageVisible && <p className="errordisplay tlevel">{errorMessage}</p>}

            <button className={"buttongold textstyle"}
            disabled={loading ? 1 : 0}
            onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
          >
            Level Up
          </button>  
          </div>                   
              </div>  
              <div className={"division3 transolevel"} />
              
      <MenuManage />
      <Notifications />
    </s.Screen>
  );
}

export default LevelUp;