import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import abi from "../abi/abi.json";
import xenomorph_abi from "../abi/xenomorph_abi.json";
import { useDispatch } from "react-redux";

function MenuLock() { 
    const dispatch = useDispatch();
    const [account, setAccount] = useState("");
    const [provider, setProvider] = useState(null); 
    const [allowanceImage, setAllowanceImage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [divisionalTitle, setDivisionalTitle] = useState("");
    const contractAddress = "0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e";
    const spenderAddress = "0xbE26470C4ab73E11e6F0157Bc3eD70D72C79Be98";
    
    const initConnection = async () => {
      if (typeof window.ethereum !== "undefined") {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(tempProvider);
        setAccount(accounts[0]);
      } else {
        console.log("Please install Metamask.");
      }
    };
  
    useEffect (() => {
      initConnection();  
    }, []);
  
    useEffect(() => {
      const fetchAllowanceData = async () => {
        try {
          if (!provider || !account || isLoading) return;
          setIsLoading(true);
  
          const signer = provider.getSigner();
          const contract = new ethers.Contract(contractAddress, abi, signer);        
          // const requiredAmount = await contract.requiredAmount.call();
          const userAllowance = await contract.allowance(account, spenderAddress);
          console.log(userAllowance);
          const contract721 = new ethers.Contract(spenderAddress, xenomorph_abi, signer);
          let amount = await contract721.requiredAmount();
          const requiredAmount = amount.toString();
          console.log(requiredAmount);
  
          if (typeof requiredAmount === 'undefined') {
            setIsLoading(false);
            return;
          }
  
        const healthLimit = requiredAmount * 50;
        const msg = (userAllowance/(10**18)).toFixed(0);
        let divisionalTitle = '';

        if (userAllowance.isZero()) {
            setAllowanceImage(null);
        } else if (userAllowance > healthLimit) {
            setAllowanceImage("approved");
            divisionalTitle = `Healthy: ${msg} xen approved for gameplay.`;
        } else if (userAllowance > requiredAmount && userAllowance < healthLimit) {
            setAllowanceImage("disapproved");
            divisionalTitle = `Re-Approve: Below healthy limit. ${msg} xen approved for gameplay.`;
        } else if (userAllowance < requiredAmount) {
            setAllowanceImage("unapproved");
            divisionalTitle = `Too Low: Less than required! ${msg} xen approved for gameplay.`;
        }

        setDivisionalTitle(divisionalTitle);

        } catch (err) {
        console.error(err);
        }
    };

  fetchAllowanceData();
}, [provider, account]);
  
    return (
       <div className="menu1" title={divisionalTitle}>
       {allowanceImage && <img src={require(`../assets/images/icons/${allowanceImage}.png`)} className="icons" style={{height: "100px"}} />}
      </div>
    );
  }
  
  export default MenuLock;
  