import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuGameplay from "./menuGameplay";
import { connect } from "../redux/blockchain/blockchainActions";

function Assault() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);
  const [attackerId, setAttackerId] = useState("");
  const [defenderId, setDefenderId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  console.log(data);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const assault = (_account, _attackerId, _defenderId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .Assault(_attackerId, _defenderId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage("Failed? Either limit surpassed or wait 24hrs to attack this Xenomorph again.");
        setErrorMessageVisible(true);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    const totalSupply = data.allPlayers.length - 1;

     // Check IDs are not an empty string
     if (attackerId === "" || defenderId === "") {
      setErrorMessage("IDs cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }

    // Check that xenomorph does not self assault
    if (attackerId == defenderId) {
      setErrorMessage("You cannot inflict self-harm");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check IDs are valid integers
    if (!integerRegex.test(attackerId) && !integerRegex.test(defenderId)) {
      setErrorMessage("IDs must be positive integers.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check that IDs are greater than 0 and less than or equal to totalSupply
    if (attackerId <= 0 || defenderId <= 0  || attackerId > totalSupply || defenderId > totalSupply) {
      setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the player owns the Xenomorph
    const ownedXenomorphs = data.allOwnerOfPlayers.filter(player => player.id === attackerId);
    if (ownedXenomorphs.length === 0) {
      setErrorMessage("You do not own this Xenomorph.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the Xenomorph has been hatched
    const ownedXenomorph = data.allOwnerOfPlayers.find(x => x.id === attackerId);
    if (ownedXenomorph.hatch === "0") {
      setLoading(false);
      setErrorMessage("You need to hatch your Xenomorph first!");
      setErrorMessageVisible(true);
      return;
    }
  
  // Check if the Xenomorph is handicapped
  if (ownedXenomorph.attack === "0") {
    setLoading(false);
    setErrorMessage("Xenomorph is handicapped. You need to weaponize first!");
    setErrorMessageVisible(true);
    return;
  }

  // Check if enemy Xenomorph has been handicapped 
  const dXenomorph = data.allPlayers.find(x => x.id === defenderId);
  if (dXenomorph.attack === "0" ) {
    setLoading(false);
    setErrorMessage("Enemy Xenomorph is already handicapped!");
    setErrorMessageVisible(true);
    return;
  }

    // Everything is valid, assault the Xenomorph
    assault(blockchain.account, attackerId, defenderId);
  };
  
  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transassault"}   />
      <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
          <img src={require("../assets/images/assaulter.gif")} className="portraitASLT" />          
            
          <div style={{padding: "2px"}}>
              <input className={"inputform"} style={{color: "white", border: "3px solid darkred", marginBottom: "0px"}}
                placeholder="Your Xenomorph ID"
                type="text"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />
          </div>
          <div>
              <input className={"inputform"} style={{color: "white", border: "3px solid darkred", marginTop: "0px"}}
                placeholder=" Enemy ID"
                type="text"
                value={defenderId}
                onChange={(e) => setDefenderId(e.target.value)}
                alt="Insert Enemy ID"
              />
          </div>

          {/* Show error message if input is invalid */}
          {errorMessageVisible && <p className="errordisplay tred">{errorMessage}</p>}

            <button className={"buttongold textstyle"}
            disabled={loading ? 1 : 0}
            onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
          >
            Assault
          </button>
      </div>

      <div className={"division3 transoassault  "} />
      <MenuGameplay />
      <Notifications />
      </s.Screen>
  );
}

export default Assault;