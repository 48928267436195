import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuTools from "./menuTools";
import { connect } from "../redux/blockchain/blockchainActions";

function UpdateName() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [attackerId, setAttackerId] = useState("");
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(true);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const updateName = (_account, _attackerId, _name) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .updateName(_attackerId, _name)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({ errorMessage });
        } else {
          dispatch(fetchData(blockchain.account));
        }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();

    // Check that attackerId is a valid integer
    if (!integerRegex.test(attackerId)) {
      setErrorMessage("ID must be a positive integer.");
      setErrorMessageVisible(true);
      return;
    }

    // Check that attackerId is greater than 0 and less than or equal to totalSupply
    const totalSupply = data.allPlayers.length;
    if (attackerId <= 0 || attackerId > totalSupply) {
      setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }

    // Check that name is not an empty string
    if (name === "") {
      setErrorMessage("Name cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }

    // All input is valid, update the name
    updateName(blockchain.account, attackerId, name);
  };

  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transname"} />

      <div
        className="translation"
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <img src={require("../assets/images/xmph.gif")} className="portraitUPN" />

        <div style={{ padding: "2px" }}>
          <input
            className={"inputform"}
            style={{ color: "white", border: "3px solid cyan", marginBottom: "0px" }}
            type="text"
            placeholder="Your Xenomorph ID"
            value={attackerId}
            onChange={(e) => setAttackerId(e.target.value)}
            alt="Your Xenomorph ID"
          />
        </div>
        <div>
            <input
              className={"inputform"}
              style={{ color: "white", border: "3px solid cyan", marginTop: "0px" }}
              type="text"
              placeholder=" New Xenomorph Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              alt="New Xenomorph Name"
            />
          </div>

          {/* Show error message if input is invalid */}
          {errorMessageVisible && <p className="errordisplay tcyan">{errorMessage}</p>}
        
          <button
            className={"buttongold textstyle"}
            disabled={loading ? 1 : 0}
            onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
          >
            Update
          </button>
        </div>
        <div className={"division3 transoname"} />

        <MenuTools />
        <Notifications />
      </s.Screen>
    );
}

export default UpdateName;
