import React, { useEffect, useState } from "react";
import "../App.css";
import { ethers } from "ethers";
import abi from "../abi/abi.json";
import { Link } from "react-router-dom"; 
import MenuLock from "./menuLock";

function Menu() {
  const [account, setAccount] = useState("");
  const [provider, setProvider] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [approval, setApproval] = useState("");
  const contractAddress = "0x2AB0e9e4eE70FFf1fB9D67031E44F6410170d00e";
  const spenderAddress = "0xbE26470C4ab73E11e6F0157Bc3eD70D72C79Be98";

  const initConnection = async () => {
    if (typeof window.ethereum !== "undefined") {
      const accounts = await window.ethereum.request({
        method: "eth_requestAccounts",
      });
      const tempProvider = new ethers.providers.Web3Provider(window.ethereum);
      setProvider(tempProvider);
      setAccount(accounts[0]);
    } else {
      console.log("Please install Metamask.");
    }
  };

  const approve = async () => {
    try {
      const signer = provider.getSigner();
      const contractERC = new ethers.Contract(
        contractAddress, 
        abi, 
        signer);      
      const approved = ethers.utils.parseEther(approval);
      const tx = await contractERC.approve(spenderAddress, approved);
      await tx.wait();
      console.log("Approval succeeded");
    } catch (err) {
      console.error(err);
    }
  };

  const revoke = async () => {
    try {
      const signer = provider.getSigner();
      const contractERC = new ethers.Contract(
        contractAddress, 
        abi, 
        signer);
      const tx = await contractERC.approve(spenderAddress, 0);
      await tx.wait();
      console.log("Revoke succeeded");
    } catch (err) {
      console.error(err);
    }
  };

  function handleShowForm() {
    setShowForm(true);
  }

  function handleHideForm() {
    setShowForm(false);
  }

  function handleSubmit(e) {
    e.preventDefault();    
    approve();
    setApproval("");
    setShowForm(false);
  }

  useEffect (() => {
  initConnection();  
  }, []);

  return (
    <div>
      <MenuLock />

      <div className="menu2">
      <Link to="/" ><div title="Return to Home"><img src={require("../assets/images/icons/home1.png")} className="icons" /></div></Link> <br />
      <Link to="/mint" ><div title="Build your Xenomorph Army"><img src={require("../assets/images/icons/start1.png")} className="icons" /></div></Link><br />    
      <Link to="/assault" ><div title="Go To Arena"><img src={require("../assets/images/icons/gameplay1.png")} className="icons" /></div></Link><br />
      <Link to="/claim-assault" ><div title="Manage Winnings"><img src={require("../assets/images/icons/manage1.png")} className="icons" /></div></Link><br />
      <Link to="/activitytracker"><div title="Toolspace"><img src={require("../assets/images/icons/tools1.png")} className="icons" /></div></Link><br /> 
      </div>

      
      <div className="menu4" style={{left: "14%", top: "69%"}}>
      <div >
      <div onClick={handleShowForm}>
      <div className="" title="Approve Access to Xen">
        <img src={require("../assets/images/icons/approval.png")} className="icons" /></div>
      </div>
              {showForm && (
                <form onSubmit={handleSubmit}>
                  <div>
                    <input
                      className={"inputform"}
                      style={{ color: "white", border: "3px solid goldenrod" }}
                      type="text"
                      placeholder="...best over 20 mil Xen"
                      value={approval}
                      onChange={(e) => setApproval(e.target.value)}
                      alt="Approval Amount"
                    />
                  </div>
                  <button className={"button1"} type="submit">Submit</button>
                  <button className={"button0"} type="button" onClick={handleHideForm}>Cancel</button>
                </form>
              )}
            </div>
            <br />
      <div className=" box3" title="Revoke Access to Xen tokens" onClick={revoke}>
      <img src={require("../assets/images/icons/revoke.png")} className="icons" /></div><br /> 
      </div>
    </div>
  );
}

export default Menu;
