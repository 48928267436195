import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuBegin from "./menuBegin";
import { connect } from "../redux/blockchain/blockchainActions";

function Mint() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  const mintFee = "0.554016620498615";

  const mint = (_account, _name) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .mint(_name)
      .send({
        from: _account,
        value: blockchain.web3.utils.toWei(mintFee, "ether"),
      })
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage("Oops, insufficient funds to mint Xenomorph!");
        setErrorMessageVisible(true);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({ errorMessage });
          setErrorMessageVisible(true);
        } else {
          dispatch(fetchData(blockchain.account));
        }
      });
  };

  const handleMintClick = (e) => {
    e.preventDefault();

    // Check that name is not an empty string
    if (name === "") {
      setErrorMessage("Name cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }

    // All input is valid, mint a new Xenomorph
    mint(blockchain.account, name);
  };

  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transmint"} />
      <div
        className="translation"
        style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
        align="center"
      >
        <img src={require("../assets/images/morphs.gif")} className="portraitMNT" />
        <div style={{ padding: "2px" }}>
          <input
            className={"inputform"}
            style={{ color: "white", border: "3px solid rgb(123, 106, 99)" }}
            type="text"
            placeholder="Name Your Xenomorph"
            value={name}
            onChange={(e) => setName(e.target.value)}
            alt="Name Your Xenomorph"
          />
        </div>

        {/* Show error message if input is invalid */}
        {errorMessageVisible && <p className="errordisplay twhitesmoke">{errorMessage}</p>}

        <button
          className={"buttongold textstyle"}
          disabled={loading ? 1 : 0}
          onClick={handleMintClick} // Pass handleMintClick as the onClick handler
        >
          Spawn
        </button>
      </div>

      <div className={"division3 transomint"} />

      <MenuBegin />
      <Notifications />
    </s.Screen>
  );
}

export default Mint;