import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuManage from "./menuManage";
import { connect } from "../redux/blockchain/blockchainActions";

function Payouts () {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);  
  const [playerId, setPlayerId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  console.log(data);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const payouts = (_account, _playerId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .Payouts(_playerId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    const totalSupply = data.allPlayers.length - 1;

     // Check ID is not an empty string
     if (playerId === "") {
      setErrorMessage("ID cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is a valid integer
    if (!integerRegex.test(playerId)) {
      setErrorMessage("ID must be positive integers.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is greater than 0 and less than or equal to totalSupply
    if (playerId <= 0  || playerId > totalSupply) {
      setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the player owns the Xenomorph
    const ownedXenomorphs = data.allOwnerOfPlayers.filter(player => player.id === playerId);
    if (ownedXenomorphs.length === 0) {
      setErrorMessage("You do not own this Xenomorph.");
      setErrorMessageVisible(true);
      return;
    }

    // Check if Xenomorph has required wins 
    const dXenomorph = data.allOwnerOfPlayers.find(x => x.id === playerId);
    if (dXenomorph.wins < 5) {
      setLoading(false);
      setErrorMessage("Xenomorph doesn't meet the minimum wins required!");
      setErrorMessageVisible(true);
      return;
    }

    // Check if Xenomorph has required level 
    if (dXenomorph.level < 1) {
      setLoading(false);
      setErrorMessage("Xenomorph doesn't meet the minimum level required!");
      setErrorMessageVisible(true);
      return;
    }

    // Check if Xenomorph has required payout 
    if (dXenomorph.payout === "0") {
      setLoading(false);
      setErrorMessage("Oops, Xenomorph has no payout!");
      setErrorMessageVisible(true);
      return;
    }

    // Everything is valid, assault the Xenomorph
    payouts(blockchain.account, playerId);
  };
  
  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transpayouts"}/>
      <div className={"translation"} 
                 style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
              <img src={require("../assets/images/cashingout.gif")} className="portraitPYT"/>
              <div>            
                <input className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
                  placeholder="Your Xenomorph ID"
                  type="text"
                  value={playerId}
                  onChange={(e) => setPlayerId(e.target.value)}
                  alt="Your Xenomorph ID"
                />

              {/* Show error message if input is invalid */}
              {errorMessageVisible && <p className="errordisplay tblue">{errorMessage}</p>}

            </div>              
                <button id="spawn-button" className={"button1 textstyle"} 
                disabled={loading ? 1 : 0}
                onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
                 style={{marginTop: "8px", border: "2px solid goldenrod" }} alt="Cashout button">
                  Cashout
                </button>                  
              </div>  
      
              <div className={"division3 transopayouts"} />
              
      <MenuManage />
      <Notifications />
    </s.Screen>
  );

}

export default Payouts;