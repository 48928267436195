import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuBegin from "./menuBegin";
import { connect } from "../redux/blockchain/blockchainActions";

function HatchXenomorph() {  
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);  
  const data = useSelector((state) => state.data);
  const [attackerId, setAttackerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const hatchxenomorph = (_account, _tokenId,) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .hatchXenomorph(_tokenId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage(errorMessage);
          setErrorMessageVisible(true);
        } else {
          dispatch(fetchData(blockchain.account));
        }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    // Check the ID is not an empty string
    if (attackerId === "") {
      setErrorMessage("ID cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check that attackerId is a valid integer
    if (!integerRegex.test(attackerId)) {
      setErrorMessage("Attacker ID must be a positive integer.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check that attackerId is greater than 0 and less than or equal to totalSupply
    const totalSupply = data.allPlayers.length - 1;
    if (attackerId <= 0 || attackerId > totalSupply) {
      setErrorMessage(`Attacker ID must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Everything is valid, hatch the Xenomorph
    hatchxenomorph(blockchain.account, attackerId);
  };
  
  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
        <div className={"division1 transhatch"} />
          <div
          className={"translation"}
          style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
          align="center"
          >
            <img src={require("../assets/images/hatch.gif")} className="portraitHTH" />
              <div style={{ padding: "2px" }}>
                <input
                className={"inputform"}
                style={{ color: "white", border: "3px solid rgba(240, 240, 192, 0.7)" }}
                placeholder="Xenomorph ID"
                type="text"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Xenomorph ID"
                />

                {/* Show error message if input is invalid */}
                {errorMessageVisible && <p className="errordisplay thatch">{errorMessage}</p>}

                <button
                  className={"buttongold textstyle"}
                  disabled={loading ? 1 : 0}
                  onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
                >
                  Hatch
                </button>
            </div>
          </div>
        <div className={"division3 transohatch"} />

      <MenuBegin />
    <Notifications />
  </s.Screen>
  );
}

export default HatchXenomorph;
