import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuManage from "./menuManage";
import { connect } from "../redux/blockchain/blockchainActions";

function ClaimAssault() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [loading, setLoading] = useState(false);  
  const [defenderId, setDefenderId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const claimassault = (_account, _defenderId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .claimAssault(_defenderId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        setErrorMessage(err.message);        
        setErrorMessageVisible(true);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    const totalSupply = data.allPlayers.length - 1;

     // Check ID is not an empty string
     if (defenderId === "") {
      setErrorMessage("IDs cannot be empty.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is a valid integer
    if (!integerRegex.test(defenderId)) {
      setErrorMessage("IDs must be positive integers.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check ID is greater than 0 and less than or equal to totalSupply
    if (defenderId <= 0  || defenderId > totalSupply) {
      setErrorMessage(`IDs must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the player owns the Xenomorph
    const ownedXenomorphs = data.allOwnerOfPlayers.filter(player => player.id === defenderId);
    if (ownedXenomorphs.length === 0) {
      setErrorMessage("You do not own this Xenomorph.");
      setErrorMessageVisible(true);
      return;
    }

    // Check if Xenomorph has pending rewards 
    const dXenomorph = data.allOwnerOfPlayers.find(x => x.id === defenderId);
    if (dXenomorph.attack < 200) {
      setLoading(false);
      setErrorMessage("Xenomorph has no pending rewards!");
      setErrorMessageVisible(true);
      return;
    }

    // Everything is valid, assault the Xenomorph
    claimassault(blockchain.account, defenderId);
  };    

  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transclaimassault"} />

      <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
          <img src={require("../assets/images/claimassault.gif")} className="portraitCASLT" />          
          <div>            
              <input className={"inputform"} style={{color: "white", border: "3px solid goldenrod"}}
                placeholder="Your Xenomorph ID"
                type="text"
                value={defenderId}
                onChange={(e) => setDefenderId(e.target.value)}
                alt="Your Xenomorph ID"
              />
          </div>

          {/* Show error message if input is invalid */}
          {errorMessageVisible && <p className="errordisplay tgoldenrod">{errorMessage}</p>}

            <button className={"button1 textstyle"}
            disabled={loading ? 1 : 0}
            onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
          >
            Claim Assault
          </button>
      </div>
        
      <div className={"division3 transoclaimassault"} />
     
      <MenuManage />
      <Notifications />
      </s.Screen>
  );
}

export default ClaimAssault;