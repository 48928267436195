import React, { useEffect } from 'react';
import "../App.css";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from './Layout';
import { GiAlienSkull } from "react-icons/gi";
import { Link } from 'react-router-dom';
import { connect } from "../redux/blockchain/blockchainActions";
import { useDispatch } from "react-redux";

function Home() { 
  const dispatch = useDispatch();  

  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  return (
    <div>
      <s.Screen image={cerberus}>
            <Layout />
            <s.Container flex={1} ai={"center"} jc={"center"}>
                <img src={require(`../assets/images/bg/welcome.png`)} className='welcomeImage'  /> 
                <p className="gradient-text" style={{fontSize: "65px"}}>
                  <span><GiAlienSkull className="skull"/></span>
                  Xenomorph 
                  <br /> 
                  Apocalypse
                  <span><GiAlienSkull className="skull" /></span>
                </p>
                <s.SpacerSmall />
                <Link to="/arsenal" className={"buttongold textstyle"}>
                  LAUNCH
                </Link>
                <s.SpacerXSmall /> 
                <s.TextSubTitle style={{fontFamily: "'Segoe UI', Tahoma, Geneva, Verdana, sans-serif", 
                                        color: "gray"}}>
                  For Xen Multichain Ecosystem
                </s.TextSubTitle>         
              </s.Container>
                
      </s.Screen>    
    </div>
  );
}

export default Home;