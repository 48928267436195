import React, { useState, useEffect } from "react";
import * as s from "../styles/globalStyles";
import cerberus from "../assets/images/bg/cerberus.gif";
import Layout from "./Layout";
import "../App.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData } from "../redux/data/dataActions";
import Notifications from "./notifications";
import MenuGameplay from "./menuGameplay";
import { connect } from "../redux/blockchain/blockchainActions";

function Regenerate() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);  
  const [attackerId, setAttackerId] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessageVisible, setErrorMessageVisible] = useState(false);

  console.log(data);

  // Regular expression to validate that input is a positive integer
  const integerRegex = /^[1-9]\d*$/;

  const regenerate = (_account, _tokenId) => {
    setLoading(true);
    blockchain.xenomorph.methods
      .regenerate(_tokenId)
      .send({
        from: _account,
      })
      .once("error", (err) => {
        setLoading(false);
        console.log(err);
      })
      .then((receipt) => {
        setLoading(false);
        if (!receipt.status) {
          // check receipt.events for error event
          setErrorMessage({errorMessage});
          setErrorMessageVisible(true);
      } else {
          dispatch(fetchData(blockchain.account));
      }
      });
  };

  const handleUpdateClick = (e) => {
    e.preventDefault();
  
    const totalSupply = data.allPlayers.length;
  
    // Check that attackerId is a valid integer
    if (!integerRegex.test(attackerId)) {
      setErrorMessage("ID must be a positive integer.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check that attackerId is greater than 0 and less than or equal to totalSupply
    if (attackerId <= 0 || attackerId > totalSupply) {
      setErrorMessage(`ID must be between 1 and ${totalSupply}.`);
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the player owns the Xenomorph
    const ownedXenomorphs = data.allOwnerOfPlayers.filter(player => player.id === attackerId);
    if (ownedXenomorphs.length === 0) {
      setErrorMessage("You do not own this Xenomorph.");
      setErrorMessageVisible(true);
      return;
    }
  
    // Check if the Xenomorph has been hatched
    const ownedXenomorph = data.allOwnerOfPlayers.find(x => x.id === attackerId);
    if (ownedXenomorph.hatch === "0") {
      setLoading(false);
      setErrorMessage("You need to hatch your Xenomorph first!");
      setErrorMessageVisible(true);
      return;
    }
  
    // Everything is valid, regenerate the Xenomorph
    regenerate(blockchain.account, attackerId);
  };   
  
  useEffect(() => {
    dispatch(connect());
  }, [dispatch]);

  useEffect(() => {
    if (blockchain.account !== "" && blockchain.xenomorph != null) {
      dispatch(fetchData(blockchain.account));
    }
  }, [blockchain.account, blockchain.xenomorph]);

  useEffect(() => {
    if (errorMessageVisible) {
      const timer = setTimeout(() => {
        setErrorMessageVisible(false);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [errorMessageVisible]);

  return (
    <s.Screen image={cerberus}>
      <Layout />
      <div className={"division1 transregenerate"}/>

      <div className="translation" style={{display: "flex", flexDirection: "column", alignItems: "center"}} align="center">
          <img src={require("../assets/images/regen.gif")} className="portraitREG" />          
          <div>
              <input className={"inputform"} style={{color: "white", border: "3px solid darkgreen"}}
                type="text"
                placeholder="Your Xenomorph ID"
                value={attackerId}
                onChange={(e) => setAttackerId(e.target.value)}
                alt="Your Xenomorph ID"
              />
              
                {/* Show error message if input is invalid */}
                {errorMessageVisible && <p className="errordisplay tred">{errorMessage}</p>}

          </div>
            <button className={"button1 textstyle"}
            disabled={loading ? 1 : 0}
            onClick={handleUpdateClick} // Pass handleUpdateClick as the onClick handler
          >
            Regenerate
          </button>
      </div>
      <div className={"division3 transoregenerate"} />
      
      <MenuGameplay />
      <Notifications />
    </s.Screen>
  );
}

export default Regenerate;