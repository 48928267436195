  import React, { useEffect, useState } from 'react';
  import './stake.css';
  import '../App.css';
  import { ethers } from "ethers";
  import xenbox_abi from "../abi/xenbox_abi.json";
  import harvester_abi from "../abi/harvester_abi.json";
  import * as s from "../styles/globalStyles";
  import cerberus from "../assets/images/bg/cerberus.gif";
  import Layout from './Layout';
  import MenuTools from './menuTools';

  function Stake () {  
    const [account, setAccount] = useState("");
    const [provider, setProvider] = useState(null);
    const [signer, setSigner] = useState(null);
    const [showForm, setShowForm] = useState(false);
    const [approval, setApproval] = useState("");
    const [inputValue, setInputValue] = useState("");
    const [senderLogs, setSenderLogs] = useState(null);
    const [loading, setLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [errorMessageVisible, setErrorMessageVisible] = useState(false);
    const xenboxAddress = "0xF9656b209BAF25b6c244E11736a675d30EB45DFF";
    const harvesterAddress = "0xba132C6E6BA97D248146704dfD23092Bd8637979";

    const initConnection = async () => {
      if (typeof window.ethereum !== "undefined") {
        const accounts = await window.ethereum.request({
          method: "eth_requestAccounts",
        });
        const tempProvider = new ethers.providers.Web3Provider(window.ethereum); 
        const signer = tempProvider.getSigner();
        setProvider(tempProvider);
        setAccount(accounts[0]);       
        setSigner(signer);      
      } else {
        console.log("Please install Metamask.");
      }
    };

    const StakeColumn = ({ className, children }) => {
      const [active, setActive] = useState(false);
    
      const handleColumnClick = (e) => {
        if (e.target === e.currentTarget) {
          setActive(!active);
        }
      };
    
      return (
        <div className={`stake-column ${className} ${active ? 'active' : ''}`} onClick={handleColumnClick}>
          {active && children}
        </div>
      );
    };
    

    const approve = async () => {
      try {
        const signer = provider.getSigner();
        const contractERC = new ethers.Contract(
          xenboxAddress, 
          xenbox_abi, 
          signer);      
        const approved = ethers.utils.parseEther(approval);
        const tx = await contractERC.approve(harvesterAddress, approved);
        await tx.wait();
        console.log("Approval succeeded");
      } catch (err) {
        console.error(err);
      }
    };

    const revoke = async () => {
      try {
        const signer = provider.getSigner();
        const contractERC = new ethers.Contract(
          xenboxAddress, 
          xenbox_abi, 
          signer);
        const tx = await contractERC.approve(harvesterAddress, 0);
        await tx.wait();
        console.log("Revoke succeeded");
      } catch (err) {
        console.error(err);
      }
    };

    const addXenbox = async () => {
      try {
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          harvesterAddress, 
          harvester_abi, 
          signer); 
        const amount = ethers.utils.parseEther(inputValue);
        const tx = await contract.addXenbox(amount);
        await tx.wait();
        console.log("succeeded");
      } catch (err) {      
        setLoading(false);
        setErrorMessage(err);
        setErrorMessageVisible(true);
        console.log(err);
      }
    };

    const withdrawXenbox = async () => {
      try {
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          harvesterAddress, 
          harvester_abi, 
          signer); 
        const tx = await contract.withdrawXenbox();
        await tx.wait();
        console.log("succeeded");
      } catch (err) {      
        setLoading(false);
        setErrorMessage(err);
        setErrorMessageVisible(true);
        console.log(err);
      }
    };

    const claimRewards = async () => {
      try {
        const signer = provider.getSigner();
        const contract = new ethers.Contract(
          harvesterAddress, 
          harvester_abi, 
          signer); 
        const tx = await contract.claim();
        await tx.wait();
        console.log("succeeded");
      } catch (err) {      
        setLoading(false);
        setErrorMessage(err);
        setErrorMessageVisible(true);
        console.log(err);
      }
    };

    const handleFunction1 = (value) => {
      setInputValue(value);
      addXenbox();
      console.log('Function 1:', value);
    };

    const handleFunction2 = () => {
      withdrawXenbox();
      console.log('Function 2');
    };

    const handleFunction3 = () => {
      claimRewards();
      console.log('Function 3');
    };

    const handleSubmit = (e, func) => {
      e.preventDefault();
      const value = e.target.elements.input1.value;
      func(value);
    };

    function handleShowForm() {
      setShowForm(true);
    }

    function handleHideForm() {
      setShowForm(false);
    }

    function handleSubmitter(e) {
      e.preventDefault();    
      approve();
      setApproval("");
      setShowForm(false);
    }

    useEffect(() => {
      initConnection();
    }, []);

    useEffect(() => {
      if (!provider || !signer) return;
    
      const contract = new ethers.Contract(harvesterAddress, harvester_abi, signer);
      contract.on("RewardClaimedByUser", (sender, rewards, event) => {
        let data = {
          sender: sender.toString(), rewards: rewards.toString(), event
        };
        setSenderLogs((data))
      });
    }, [provider, signer]);

    return (
      <s.Screen image={cerberus}>
        <Layout />
          <div className="stake-container">
            <StakeColumn className="column-1">
              <form onSubmit={(e) => handleSubmit(e, handleFunction1)} className="stake-form">
                <input name="input1" type="text" placeholder="Enter Xenbox Amount" />
                <button type="submit">Mine Now</button>
              </form>
            </StakeColumn>
            <StakeColumn className="column-2">
              <button onClick={handleFunction2} className="stake-form stake-button">
                Withdraw Now
              </button>
            </StakeColumn>
            <StakeColumn className="column-3">
              <button onClick={handleFunction3} className="stake-form stake-button">
                Harvest Now
              </button>
            </StakeColumn>

            <div className='notificationsbar'>
            {senderLogs && (
                <div className="stakenotify texttheme tgoldenrod">
                  Reward claim successful! 
                  <span style={{color: "white"}}>Receipent:</span> 
                  ...{senderLogs.sender.substring(senderLogs.sender.length - 12)} 
                  <span style={{color: "white"}}>Rewards:</span> {senderLogs.rewards/(10**18).toFixed()} $XEN
                </div>
              )}
            </div>

          </div>

          <div className="menu4" style={{left: "14%", top: "69%"}}>
        <div >
        <div onClick={handleShowForm}>
        <div className="" title="Approve Access to Xenbox Tokens">
          <img src={require("../assets/images/icons/approvalx.png")} className="icons" /></div>
        </div>
                {showForm && (
                  <form onSubmit={handleSubmitter}>
                    <div>
                      <input
                        className={"inputform"}
                        style={{ color: "white", border: "3px solid goldenrod" }}
                        type="text"
                        placeholder="...best over 20 mil Xen"
                        value={approval}
                        onChange={(e) => setApproval(e.target.value)}
                        alt="Approval Amount"
                      />
                    </div>
                    <button className={"button1"} type="submit">Submit</button>
                    <button className={"button0"} type="button" onClick={handleHideForm}>Cancel</button>
                  </form>
                )}
              </div>
              <br />
        <div className=" box3" title="Revoke Access to Xenbox tokens" onClick={revoke}>
        <img src={require("../assets/images/icons/revoke.png")} className="icons" /></div><br /> 
        </div>

        <MenuTools />
      </s.Screen>
    );
  };

  export default Stake;